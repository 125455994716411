export enum BuildersListKey {
  listOrderOperations = 'list_order_operations',
  listCompletedOrderOperations = 'list_completed_order_operations',
  listOrderOperationDisruptions = 'list_order_operation_disruptions',
  listComponents = 'list_components',
  listQualityInspectionResults = 'list_quality_inspection_results',
  listAdminDisruptionTypes = 'list_admin_disruption_types',
  listAdminDisruptionTypeValues = 'list_admin_disruption_type_values',
  listUsers = 'list_users',
}

export enum BuildersFormKey {
  formOrderDetails = 'form_order_details',
  formOrderOperationDetails = 'form_order_operation_details',
  formOrderOperationDisruption = 'form_order_operation_disruption',
  formCompletedOrderOperationDisruption = 'form_completed_order_operation_disruption',
  formQualityDeviation = 'form_quality_deviation',
  formQualityInspection = 'form_quality_inspection',
  formAdminDisruptionType = 'form_admin_disruption_type',
  formAdminDisruptionTypeValue = 'form_admin_disruption_value',
  formAdminUser = 'form_user',
  formOperatorProfile = 'form_operator_profile',
}
